import React, { useEffect, useState } from 'react';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import { time } from 'console';
// import { endAt } from 'firebase/database';
// import DataRange from './DataRange';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

export const options = {
    layout: {
        padding: 10
    },
    responsive: true,
    elements: {
        point: {
            radius: 2,
            hoverRadius: 7,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            text: 'Count Chart',
        },
    },
    scales: {
        x: {
            display: true,
            title: {
                display: false,
                text: 'Month'
            },
            grid: {
                color: "rgba(255,255,255,0)",
            },
        },
        y: {
            display: true,
            title: {
                display: false,
                text: 'Value'
            },
            grid: {
                color: "rgba(0,0,0, 0)",
            },
        },
    }
};


export const LineChartCount = ({ setSelectedTimeCount, isCustomRange, startDate, setStartDate, endDate, setEndDate, x, devices, selectedDatasState, selectedDevicesState, selectedShop }) => {
    const [labels, setLabels] = useState([])
    const [timeStampsState, setTimeStampsState] = useState()
    const [lineData, setLineData] = useState()
    const [Button2Data, setButton2Data] = useState()

    let data = {
        labels,
        datasets: [
            {
                label: '',
                data: lineData,
                borderColor: '#a87b52',
                backgroundColor: '#fff',
                color: "#fff",
                pointHoverBackgroundColor: "#a87b52",
            },
        ],
    };
    useEffect(() => {
        if (x[0]) {
        }
    }, [x, devices])

    useEffect(() => {
        if (selectedDatasState && selectedDatasState.length > 0) {
            selectedDatasState.forEach((data) => {
            })
            const selectedTimesDatas = selectedDatasState.filter((data) => data.length > 0 && data[0][0] <= endDate.getTime() && data[0][0] >= startDate.getTime())

            let timeStamps = []
            selectedDevicesState.forEach((item) => {
                item.forEach((inItem) => {
                    let temp = devices.filter((device) => device[0] == inItem)
                    timeStamps.push(temp)
                })
            })
            //console.warn("timeStamps", timeStamps)

            let deviceIdAndPrice = []
            timeStamps.forEach((timeStamp) => {
                if (timeStamp && timeStamp.length > 0) {
                    let deviceId = timeStamp[0][0]
                    selectedDatasState.forEach((data) => {
                        if (data.length > 0 && data[0] && data[0][1] && data[0][1].devices) {
                            Object.entries(data[0][1].devices).forEach((inData) => {
                                if (inData[0] == deviceId) {
                                    if (timeStamp[0][1].Button1 && !timeStamp[0][1].Button2) {
                                        deviceIdAndPrice.push({
                                            "id": deviceId,
                                            "price": data[0][1].sell_price,
                                            "Button1Times": Object.values(timeStamp[0][1].Button1.Timestamp).map((item) => item.Ts),
                                            "Button2Times": []
                                        })
                                    } else if (timeStamp[0][1].Button1 && timeStamp[0][1].Button2) {
                                        deviceIdAndPrice.push({
                                            "id": deviceId,
                                            "price": data[0][1].sell_price,
                                            "Button1Times": Object.values(timeStamp[0][1].Button1.Timestamp).map((item) => item.Ts),
                                            "Button2Times": Object.values(timeStamp[0][1].Button2.Timestamp).map((item) => item.Ts)
                                        })
                                    } else if (!timeStamp[0][1].Button1 && timeStamp[0][1].Button2) {
                                        deviceIdAndPrice.push({
                                            "id": deviceId,
                                            "price": data[0][1].sell_price,
                                            "Button1Times": [],
                                            "Button2Times": Object.values(timeStamp[0][1].Button2.Timestamp).map((item) => item.Ts)
                                        })
                                    } else if (!timeStamp[0][1].Button1 && !timeStamp[0][1].Button2) {
                                        deviceIdAndPrice.push({
                                            "id": deviceId,
                                            "price": data[0][1].sell_price,
                                            "Button1Times": [],
                                            "Button2Times": []
                                        })
                                    }
                                }
                            })
                        }
                    })
                }
            })
            setTimeStampsState(deviceIdAndPrice)
        }


    }, [startDate, endDate, selectedDatasState, selectedDevicesState, devices, selectedShop])


    const handleLabel = () => {

        let startDay = startDate.getDate()
        let startMonth = (startDate.getMonth() + 1)
        let startYear = startDate.getFullYear()

        let endDay = endDate.getDate()
        let endMounth = (endDate.getMonth() + 1)
        let endYear = endDate.getFullYear()


        let gap = endDate.getTime() - startDate.getTime()
        let dayGap = Math.round(gap / 86400000)

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        let tempLabels = []
        let tempData = []
        let tempData2 = []
        let uniqeTempData = []
        let finalData = []

        if (timeStampsState != undefined) {
            timeStampsState.forEach((item, itemIndex) => {
                item.Button1Times.forEach((time, timeIndex) => {
                    let date = new Date(time)
                    if (time >= startDate.getTime() && time <= endDate.getTime()) {
                        tempData.push({
                            "date": time,
                            "price": item.price
                        })
                    }
                })

                item.Button2Times.forEach((time, timeIndex) => {
                    let date = new Date(time)
                    if (time >= startDate.getTime() && time <= endDate.getTime()) {
                        // //console.log(timeStampsState[itemIndex].Button1Times[timeIndex], date.getFullYear())
                        tempData.push({
                            "date": time,
                            "price": (item.price)
                        })
                        tempData.push({
                            "date": time,
                            "price": (item.price)
                        })
                    }
                })

            })
            let data = tempData
            //console.warn("dayGap:", dayGap)
            //console.warn("tempData:", tempData)


            const times = []
            tempData.forEach((data) => {
                times.push(data.date)
            })
            const sortedTimes = times.sort((a, b) => a - b);



            //console.warn("sortedTimes:", sortedTimes)

            let days = [];
            let dayCounts = [];
            let day = new Date(startDate.getTime()).toLocaleDateString();
            let count = 0;
            for (let i = 0; i < sortedTimes.length; i++) {
                let currentDate = new Date(sortedTimes[i]).toLocaleDateString();
                if (currentDate === day) {
                    count++;
                } else {
                    days.push(day);
                    dayCounts.push(count);
                    day = currentDate;
                    count = 1;
                }
            }
            // son günü ekle
            days.push(day);
            dayCounts.push(count);

            // eksik günleri ekle
            let allDays = [];
            let currentDay = new Date(startDate);
            let lastDay = new Date(endDate.getTime() - 86400000);
            while (currentDay <= lastDay) {
                allDays.push(currentDay.toLocaleDateString());
                currentDay.setDate(currentDay.getDate() + 1);
            }
            // eksik günleri bul ve count'ları sıfırla
            for (let i = 0; i < allDays.length; i++) {
                if (!days.includes(allDays[i])) {
                    days.splice(i, 0, allDays[i]);
                    dayCounts.splice(i, 0, 0);
                }
            }

            //console.log(days); // tarihler
            //console.log(dayCounts); // tarihlerdeki öğe sayıları



            setLineData(dayCounts)
            setLabels(days)

            let tempCount = 0
            dayCounts.forEach((item) => {
                tempCount += item
            })
            setSelectedTimeCount(tempCount)
        } else {


        }




    }

    useEffect(() => {
        ////console.log(isCustomRange)
        handleLabel()
    }, [isCustomRange])


    useEffect(() => {
        handleLabel()
    }, [startDate, endDate, selectedShop, timeStampsState, devices])


    useEffect(() => {
        setStartDate(startDate)
    }, [selectedShop])

    return (
        <>
            <Line options={options} data={data} />
        </>
    );
}
