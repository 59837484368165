import React, { useEffect, useState, useRef } from 'react';
// import { QrReader } from 'react-qr-reader';
import QrReader from 'modern-react-qr-reader'
import Select from 'react-select';
import { child, ref, set, onValue, remove} from "firebase/database";
import { db } from "../../firebase/FireBase"
import Switch from 'react-input-switch';



// let options = []
const DeleteQR = () => {
    const [padding, setPadding] = useState(false)
    const [options, setOptions] = useState([])
    const [swtichValue, setSwtichValue] = useState(0);
    const [scanState, setScanState] = useState(false)
    const [locations, setLocations] = useState(null)
    const [scanRes, setScanRes] = useState(0)
    const [selectedLocations, setSelectedLocations] = useState(null)
    const [errorModal, setErrorModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const sendDataRef = useRef(null)
    const breathSvg = useRef()

    const dbRefValidCoffeeSets = child(ref(db), 'cup-ids/');
    // const dbRefValidCoffeeSets = child(ref(db), 'valid-coffee-sets/');
    //console.log("scanRes", scanRes)
    let coffeeSets;
    onValue(dbRefValidCoffeeSets, (snapshot) => {
        console.log("in")
        if (snapshot.exists()) {
            coffeeSets = snapshot.val();
        }
    }, { onlyOnce: true })

    window.onkeydown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            sendDataRef?.current.click()
        }
    }

    const coffeSet = useRef()
    // select locations input ------------------------------------------------------------
    const valueChanged = (value) => {
        setSelectedLocations(value.value)
    }
    const dbRefLocations = child(ref(db), `locations/`);
    useEffect(() => {
        onValue(dbRefLocations, (snapshot) => {
            if (snapshot.exists()) {
                setLocations(Object.entries(snapshot.val()))
            }
        })
    }, [])
    useEffect(() => {
        //console.warn(locations)
        let options = []
        locations?.forEach((location) => {
            options.push({ "value": location[1].location_id, "label": location[1].location_name })
        })
        setOptions(options)
    }, [locations])
    // select locations input ------------------------------------------------------------


    const handleError = (err) => {
        console.error(err);
    }
    const handleScan = (data) => {
        if (data) {
            console.log("Scan Response:", data);

            const splittedRes = data.split("/");
            const desiredPart = splittedRes[splittedRes.length - 1];

            console.log(coffeeSets);
            for (const key in coffeeSets) {
                if (key === desiredPart) {   
                    setScanRes(coffeeSets[key])
                    break; // Exit the loop once the key is found
                }
            }
            //setScanRes("res?.text")
            breathSvg.current.style.cssText = "opacity: 1; transition: .3s;"
            setTimeout(() => {
                breathSvg.current.style.cssText = "opacity: 0; transition: .3s;"
            }, 300)
        } else {
            //console.log("hey")
        }
    }

    const closeCam = () => {
        window.location.reload()
        setScanState(false)
    };
    
    const deleteData = () => {
        console.log(selectedLocations + " - " + scanRes)
        if (scanRes.length != 0) {
            const dbRefCoffeeSets = child(ref(db), `locations/` + `${selectedLocations}` + "/coffee_sets/" + scanRes);
            setPadding(true);
            remove(dbRefCoffeeSets).then((res) => {
                console.log(res)
                setSuccessModal(true)
            }).catch((error) => {
                setErrorModal(true)
                console.error(error)
            });
        }
    }



    let swtichCount = 0
    const handleSwitch = () => {
        //console.log(swtichCount)
        if (swtichCount === 0) {
            setSwtichValue(1)
            setScanState(true)
            swtichCount++
        } else {
            setSwtichValue(0)
            setScanState(false)
            closeCam()
        }
    }

    return (
        <div id="addCoffeeSet">
            <div id="myModal" className={errorModal ? "modal fade show" : "modal fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" /></svg>
                            <button type="button" className="close" onClick={() => setErrorModal(false)} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Ooops!</h4>
                            <p>Something went wrong.</p>
                            <button className="btn btn-success" onClick={deleteData} data-dismiss="modal">Try Again</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myModal" className={successModal ? "modal success fade show" : "modal success fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z" /></svg>
                            <button type="button" className="close" onClick={() => setSuccessModal(false)} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Great!</h4>
                            <p>Cup(s) has been successfully Deleted.</p>
                            <button className="btn btn-success" onClick={() => setSuccessModal(false)} data-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="label--input">
                <label>Select Location</label>
                <Select id='job-selector' options={options} onChange={valueChanged} />
            </div>
            <div className='switch--label'>

                <Switch value={swtichValue} onChange={handleSwitch} styles={{
                    track: {
                        backgroundColor: '#ddd'
                    },
                    trackChecked: {
                        backgroundColor: '#e3c02a'
                    },
                    button: {
                        backgroundColor: '#9f9f9f'
                    },
                    buttonChecked: {
                        backgroundColor: '#fff'
                    }
                }} />
                <p>{swtichValue === 0 ? "Turn on the camera" : "Turn of the camera"}</p>
            </div>
            {/* <button onClick={() => setScanState(!scanState)} >{scanState ? "Stop Scan" : "Start Scan"}</button> */}
            <svg id='breath-svg' fill='green' ref={breathSvg} xmlns="http://www.w3.org/2000/svg" height="60" viewBox="0 96 960 960" width="60"><path d="m421 758 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Z" /></svg>
            {
                scanState ?
                    <>
                        <QrReader
                            delay={300}
                            facingMode={"environment"}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '100%' }}
                        />
                        <div className='label--input'>
                            <label>Coffee Cup</label>
                            <input ref={coffeSet} value={scanRes} onChange={(e) => setScanRes(e.target.value)} type='text' />
                        </div>
                        <button ref={sendDataRef} className={padding ? 'sendDataButton padding' : 'sendDataButton'} onClick={deleteData} >
                            Delete Cup(s)
                        </button>
                    </> :
                    null
            }
        </div>
    );
};

export default DeleteQR;
