import React, { useEffect, useState, useRef } from "react";
// import { app } from "./FireBase"
import { sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { ref, child, onValue } from "firebase/database"
import { auth, dbRefAdmins, dbRefTech, dbRefUser, db } from "../../firebase/FireBase"
import { SpinnerRoundFilled } from 'spinners-react'
import passwordHide from "../../images/passwordHide.svg"
import passwordShow from "../../images/passwordShow.svg"
import brandLogo from "../../images/login_logo.png"


const Login = ({ users, setUsers, admins, setAdmins, tech, setTech, setUserCred, setIsLoggedIn }) => {
    const date = new Date()

    const emailRef = useRef()
    const passwordRef = useRef()
    const [remember, setRemember] = useState(false)

    const [padding, setPadding] = useState(false)
    const [error, setError] = useState([])
    const [passwordType, setPasswordType] = useState(true)
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalM, setErrorModalM] = useState("error");

    const [successModal, setSuccessModal] = useState(false);
    const signInButtonRef = useRef()
    const [usersCreds, setUsersCreds] = useState(null)

    window.onkeydown = (event) => {
        if (event.key === "Enter" && signInButtonRef && signInButtonRef.current) {
            event.preventDefault()
            if (signInButtonRef) {
                signInButtonRef?.current.click()
            }
        }
    }




    const handleSignIn = () => {
        if (emailRef.current.value && passwordRef.current.value) {
            setPadding(true)

            if (admins && admins.length > 0 && tech && tech.length > 0 && users && users.length > 0 && emailRef.current.value && passwordRef.current.value) {

                signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)
                    .then((userCredential) => {
                        setPadding(false)

                        sessionStorage.setItem("isLoggin", `true`)
                        sessionStorage.setItem("email", `${emailRef.current.value}`)
                        sessionStorage.setItem("password", `${passwordRef.current.value}`)

                        if (remember) {
                            let email = emailRef.current.value
                            let password = passwordRef.current.value
                            localStorage.setItem("email", email)
                            localStorage.setItem("password", password)
                        } else {
                        }
                        // Signed in 

                        const user = userCredential.user;
                        console.log("user", user)

                        admins.forEach((admin) => {
                            if (user.uid == admin[0] && admin[1] == true) {
                                setIsLoggedIn("admin")
                                sessionStorage.setItem("isLoggin", `admin`)
                            }
                        })
                        tech.forEach((tech) => {
                            if (user.uid == tech[0] && tech[1] == true) {
                                setIsLoggedIn("tech")
                                sessionStorage.setItem("isLoggin", `tech`);
                                sessionStorage.setItem("TechUid", user.uid);
                                sessionStorage.setItem("TechnecianEmail", user.email);
                            }
                        })
                        users.forEach((userr) => {
                            if (user.uid == userr[0] && userr[1].valid && userr[1].valid == true) {
                                setIsLoggedIn("user")
                                setUserCred(user)
                                sessionStorage.setItem("isLoggin", `user`)
                            }
                        })
                        usersCreds.forEach((usersCred) => {
                            // console.log(usersCreds)
                            if (user.uid == usersCred[0] && usersCred[1].Role == "Owner") {
                                console.log(usersCred)
                                setIsLoggedIn("owner")
                                setUserCred(usersCred)
                                sessionStorage.setItem("isLoggin", `owner`)
                            }
                        })
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        //console.e.e.e.e.e.error(errorMessage)
                        setError(error)
                        setErrorModal(true)
                        setErrorModalM("User credentials are invalid")
                        setPadding(false)
                    });

            }
        }
    }


    useEffect(() => {
        onValue(dbRefAdmins, (snapshot) => {
            if (snapshot.exists()) {
                setAdmins(Object.entries(snapshot.val()))
                // handleSignIn()
            }
        })
        onValue(dbRefTech, (snapshot) => {
            if (snapshot.exists()) {
                setTech(Object.entries(snapshot.val()))
                // handleSignIn()
            }
        })
        onValue(dbRefUser, (snapshot) => {
            if (snapshot.exists()) {
                setUsers(Object.entries(snapshot.val()))
                // handleSignIn()
            }
        })
        const dbRefUsersCreds = child(ref(db), `usersCreds/`);
        onValue(dbRefUsersCreds, (snapshot) => {
            if (snapshot.exists()) {
                setUsersCreds(Object.entries(snapshot.val()))
                // handleSignIn()
            }
        })

        setPadding(false)
        //console.log("padding")

        let loginTimeMs = localStorage.getItem("loginTime")
        let loginRecordMs = date.getTime() - loginTimeMs
        //console.log(new Date(loginRecordMs).getHours() - 2)
    }, [])

    const buttonRenderer = () => {
        if (padding == false) {
            return <p>Login</p>
        } else {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <p>Loading</p>
                    <SpinnerRoundFilled size={35} thickness={100} speed={100} color="#e3c02a" />
                </div>
            )
        }
    }
    useEffect(() => {
        buttonRenderer()
        //console.e.e.log(padding)
    }, [padding])

    // useEffect(() => {
    //    handleSignIn()
    // }, [admins, tech])
    // =============================================================================================================================================
    // =========>>     SIGN IN    <<================================================================================================================
    // =============================================================================================================================================

    // =============================================================================================================================================
    // =========>>                <<================================================================================================================
    // =============================================================================================================================================




    const triggerResetEmail = async () => {
        if (emailRef.current.value) {
            setSuccessModal(true)
            await sendPasswordResetEmail(auth, emailRef.current.value);
        } else {
            setErrorModal(true)
            setErrorModalM("Please enter valid email address")
        }
    }





    useEffect(() => {
        let sesionLoggin = sessionStorage.getItem("isLoggin")
        let email = sessionStorage.getItem("email")
        let password = sessionStorage.getItem("password")

        if (sesionLoggin && sesionLoggin != "false") {
            signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
                const user = userCredential.user;
                setPadding(false)
                setUserCred(user)
            })
        } else {

        }
        let localEmail = localStorage.getItem("email")
        let localPassword = localStorage.getItem("password")

        if (localEmail && localPassword) {
            emailRef.current.value = localEmail
            passwordRef.current.value = localPassword
        }


    }, [])
    return (
        <>


            <div id="login-container">
                <img src={brandLogo}></img>
                <div id="login-title-para">
                    <h1>Welcome to</h1>
                    <h1>Fortune Teller Coffee</h1>
                    <h1>Partner Portal</h1>
                </div>

                <div className="input--label">
                    <bar></bar>
                    <input ref={emailRef}
                        // defaultValue=
                        // {
                        //     localStorage.getItem("rememberEmail") ?
                        //         localStorage.getItem("rememberEmail") :
                        //         null
                        // }
                        className="email" placeholder="mail@example.com" />
                    <label>Email</label>
                </div>
                <div className="input--label">
                    <bar></bar>
                    <input ref={passwordRef} type={passwordType ? "password" : "text"}
                        // defaultValue=
                        // {
                        //     localStorage.getItem("rememberPassword") ?
                        //         null :
                        //         null
                        // }
                        className="email" placeholder="At least 6 characters" />
                    <label>Password</label>
                    <img id="passwordToggle" src={
                        passwordType ? passwordShow :
                            passwordHide
                    }
                        onClick={() => setPasswordType(!passwordType)}>
                    </img>
                </div>
                <div id="modal-static" className={`${errorModal}`}>
                    <p>{errorModalM}</p>
                </div>
                <div id="remember--signup">
                    <div id="rememberMe">
                        <div className="custom-checkbox">
                            <input onClick={() => setRemember(!remember)} type="checkbox" />
                            <span></span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z" /></svg>
                        </div>
                        <p>Remember me</p>
                    </div>

                </div>



                <div id="button-wrapper">
                    <button ref={signInButtonRef} id="sign-in" className={padding ? "padding" : null} onClick={handleSignIn} >
                        {buttonRenderer()}
                    </button>
                </div>
                <div id="forgot-password-div">
                    <p onClick={triggerResetEmail} >Forgot Password?</p>
                </div>
                <p className="app-version">Version: {process.env.REACT_APP_VERSION}</p>
                <div id="modal" className={`success ${successModal}`}>
                    <p>If there is a registered user, we’ve sent you an email. Please check your inbox and follow steps to assign a new password.</p>
                </div>
            </div>

        </>
    )
}

export default Login