import React, { useEffect, useRef, useState } from 'react';
// import { MapContainer, Popup, Marker, TileLayer, useMapEvents } from 'react-leaflet';
// import makeAnimated from 'react-select/animated';
import { child, getDatabase, ref, set, update, onValue, get } from "firebase/database";
// import Leaflet from "./LeafLet.js"
// import JobSelector from "./JobSelector.js"
import { db } from "../../firebase/FireBase.js"
// import { createUserWithEmailAndPassword } from 'firebase/auth';
import Select from 'react-select';
// import { getAllByAltText } from '@testing-library/react';


let options = []

const LailaService = () => {
  const retailPriceRef = useRef()
  const revenueShareRef = useRef()
  const lailaServicePriceRef = useRef()
  const currencyRef = useRef()
  const businessRef = useRef()
  const sendDataRef = useRef(null)
  const [currencyValue, setCurrencyValue] = useState(null)
  const [isSellPrice, setIsSellPrice] = useState(false);
  const [businessModelValue, setbusinessModelValue] = useState({ "value": "revenue_share", "label": "Revenue share" })

  // Location selectbox change handler, from here the process starts
  const valueChanged = (value) => {
    setSelectedLocations(value.value)
    const dbRefLocation = child(ref(db), `locations/` + `${value.value}`);
    get(dbRefLocation).then((snapshot) => {
      if (snapshot.exists()) {
        let currentLocations = snapshot.val()
        // to disable add price button if revenue share is there
        if ('sell_price' in currentLocations || 'single_cup_sell_price' in currentLocations)
        setIsSellPrice(true)
        else
        setIsSellPrice(false)

        if (currentLocations.sell_price)
          retailPriceRef.current.value = currentLocations.sell_price;
        revenueShareRef.current.value = currentLocations.revenue_share;
        currencyRef.current.value = currentLocations.currency;
        currencyRef.current.selectOption(currentLocations.currency ? currentLocations.currency : "")
        const currencySelectHandler = (movieId) => {
          const movie = currencyOptions.find((movie) => movie.value === movieId);
          return movie ? movie : null;
        }
        const businessModelSelectHandler = (movieId) => {
          const movie = businessModelOptions.find((movie) => movie.value === movieId);
          return movie ? movie : null;
        }
        setCurrencyValue(currencySelectHandler(currentLocations.currency))
        setSelectedCurrency(currentLocations.currency)
        if (currentLocations.business_model) {
          setbusinessModelValue(businessModelSelectHandler(currentLocations.business_model));
          setSelectedbussModel(businessModelSelectHandler(value.value));
        }
        else {
          setbusinessModelValue(businessModelSelectHandler("revenue_share"));
          setSelectedbussModel(businessModelSelectHandler("revenue_share"));
        }
        if (currentLocations.laila_service_price) {
          lailaServicePriceRef.current.value = currentLocations.laila_service_price;
        }
      }
    });

  }
  const currencyValueChanged = (value) => {
    setSelectedCurrency(value.value)
    setCurrencyValue(value)
  }
  const bussModelValueChanged = (value) => {
    setSelectedbussModel(value.value)
    setbusinessModelValue(value)
  }
  const [padding, setPadding] = useState(false)
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [options, setOptions] = useState([])

  const [selectedLocations, setSelectedLocations] = useState(null)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [selectedbussModel, setSelectedbussModel] = useState(null)
  const [locations, setLocations] = useState(null)
  const [TechUid, setTechUid] = useState(sessionStorage.getItem("TechUid"))
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [businessModelOptions, setBusinessModelOptions] = useState([
    { "value": "flat_fee", "label": "Flat fee" },
    { "value": "revenue_share", "label": "Revenue share" }
  ]);

  window.onkeydown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      sendDataRef?.current.click()
    }
  }

  const locationId = new Date().getTime();

  const dbRefLocations = child(ref(db), `locations/`);
  const dbRefCurrencies = child(ref(db), `valid-currencies/`);

  // {"value":"USD ($)", "label":"USD ($)"},

  useEffect(() => {
    onValue(dbRefLocations, (snapshot) => {
      if (snapshot.exists()) {
        setLocations(Object.entries(snapshot.val()))
      }
    })
    onValue(dbRefCurrencies, (snapshot) => {
      if (snapshot.exists()) {
        let currencies = [];
        for (const [key, value] of Object.entries(snapshot.val())) {
          currencies.push({ "value": key, "label": value })
        }
        setCurrencyOptions(currencies)
      }
    })
  }, [])

  useEffect(() => {
    //console.warn(locations)
    let options = []
    locations?.forEach((location) => {
      options.push({ "value": location[1].location_id, "label": location[1].location_name })
    })
    setOptions(options)
  }, [locations])

  const sendData = () => {

    if (selectedLocations) {
      setPadding(true)
      let sell_price = parseFloat(retailPriceRef.current.value);
      let revenue_share = parseFloat(revenueShareRef.current.value);
      let laila_service_price = parseFloat(lailaServicePriceRef.current.value);
      const dbRefLoc = child(ref(db), `locations/` + selectedLocations);
      console.log(selectedCurrency)
      if (businessModelValue.value == "flat_fee") {
        update((dbRefLoc), {
          currency: selectedCurrency,
          business_model: "flat_fee",
          laila_service_price: laila_service_price
        }).then(() => {
          const hestoricalPricesRef = child(ref(db), `locations/` + selectedLocations + `/historical_price/` + new Date().getTime());
          set((hestoricalPricesRef), {
            currency: selectedCurrency,
            laila_service_price: laila_service_price,
            technician: TechUid
          }).then(() => {
            setSuccessModal(true)
          }).catch((error) => {
            //console.error(error)
          });
        }).catch((error) => {
          //console.error(error)
        });
      }
      else {
        update((dbRefLoc), {
          currency: selectedCurrency,
          business_model: "revenue_share",
          sell_price: sell_price,
          revenue_share: revenue_share
        }).then(() => {
          const hestoricalPricesRef = child(ref(db), `locations/` + selectedLocations + `/historical_price/` + new Date().getTime());
          set((hestoricalPricesRef), {
            currency: selectedCurrency,
            sell_price: sell_price,
            revenue_share: revenue_share,
            technician: TechUid
          }).then(() => {
            setSuccessModal(true)
          }).catch((error) => {
            //console.error(error)
          });
        }).catch((error) => {
          //console.error(error)
        });
      }
    }
    else
      setErrorModal(true)
  }
  const pageRefresh = () => {
    window.location.reload()
  }
  return (
    <div id='addDevices'>
      <div id="myModal" className={errorModal ? "modal fade show" : "modal fade"}>
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" /></svg>
              <button type="button" className="close" onClick={() => setErrorModal(false)} data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body text-center">
              <h4>Ooops!</h4>
              <p>Something went wrong.</p>
              <button className="btn btn-success" onClick={sendData} data-dismiss="modal">Try Again</button>
            </div>
          </div>
        </div>
      </div>
      <div id="myModal" className={successModal ? "modal success fade show" : "modal success fade"}>
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z" /></svg>
              <button type="button" className="close" onClick={() => window.location.reload()} data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body text-center">
              <h4>Great!</h4>
              <p>Price has been successfully added.</p>
              <button className="btn btn-success" onClick={() => window.location.reload()} data-dismiss="modal">Ok</button>
            </div>
          </div>
        </div>
      </div>
      {/* service not allowed modal */}
      <div id="myModal" className={isSellPrice ? "modal fade show" : "modal fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" /></svg>
                            <button type="button" className="close" onClick={pageRefresh} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Ooops!</h4>
                            <p>This service cannot be added to this location.</p>
                            <button className="btn btn-success" onClick={pageRefresh} data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


      <div className="label--input">
        <label>Select Location</label>
        <Select id='job-selector' options={options} onChange={valueChanged} />
      </div>
      <div className='label--input'>
        <label>Currency</label>
        <Select
          value={currencyValue}
          id='job-selector'
          ref={currencyRef}
          options={currencyOptions}
          onChange={currencyValueChanged}
        />
      </div>
      <div className='label--input'>
        <label>Business model</label>
        <Select
          value={businessModelValue}
          id='job-selector'
          ref={businessRef}
          options={businessModelOptions}
          onChange={bussModelValueChanged}
        />
      </div>
      {
        businessModelValue && businessModelValue.value == "flat_fee" ? (
          <>
            <div className='label--input'>
              <label>Laila service price</label>
              <input
                type="number"
                min="0"
                ref={lailaServicePriceRef}
                placeholder='0.00'
              />
            </div>
            <div className='label--input d-none'>
              <label>Session retail price</label>
              <input
                type="number"
                min="0"
                ref={retailPriceRef}
                placeholder='0.00'
              />
            </div>
            <div className='label--input d-none'>
              <label>Revenue share</label>
              <input
                type="number"
                min="0"
                ref={revenueShareRef}
                placeholder='0.00'
              />
            </div>
          </>
        ) : (
          <>
            <div className='label--input d-none'>
              <label>Laila service price</label>
              <input
                type="number"
                min="0"
                ref={lailaServicePriceRef}
                placeholder='0.00'
              />
            </div>
            <div className='label--input'>
              <label>Session retail price</label>
              <input
                type="number"
                min="0"
                ref={retailPriceRef}
                placeholder='0.00'
              />
            </div>
            <div className='label--input'>
              <label>Revenue share</label>
              <input
                type="number"
                min="0"
                ref={revenueShareRef}
                placeholder='0.00'
              />
            </div>
          </>
        )
      }
      <button ref={sendDataRef} className={padding ? 'sendDataButton padding' : 'sendDataButton'} onClick={sendData} disabled={isSellPrice ? true : false}>Add price</button>
    </div>
  )
}

export default LailaService;