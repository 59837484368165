import { useEffect, useState } from 'react';
import Select from 'react-select';
import { child, ref, set, update, onValue} from "firebase/database";
import { useRef } from 'react';
import { db } from "../../firebase/FireBase"



const AddStockToLoc = () => {
    // let options = []
    const [errorModal, setErrorModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [padding, setPadding] = useState(false)

    const [options, setOptions] = useState([])
    const [selectedLocations, setSelectedLocations] = useState(null)
    const [locations, setLocations] = useState(null)
    const [currentThreshold, setCurrentThreshold] = useState(null)
    const [TechUid, setTechUid] = useState(sessionStorage.getItem("TechUid"))
    const delightStockRef = useRef()
    // const coffeStockRef = useRef()

    const [coffeStockRef, setCoffeStockRef] = useState('');

    const coffeeStockOptions = [];
    coffeeStockOptions.push({ "value": "100", "label": "100 gr (0.1 kg)" })
    coffeeStockOptions.push({ "value": "250", "label": "250 gr (0.25 kg)" })
    coffeeStockOptions.push({ "value": "500", "label": "500 gr (0.50 kg)" })
    coffeeStockOptions.push({ "value": "1000", "label": "1000 gr (1.00 kg)" })
    coffeeStockOptions.push({ "value": "2000", "label": "2000 gr (2.00 kg)" })
    coffeeStockOptions.push({ "value": "3000", "label": "3000 gr (3.00 kg)" })
    coffeeStockOptions.push({ "value": "4000", "label": "4000 gr (4.00 kg)" })
    coffeeStockOptions.push({ "value": "5000", "label": "5000 gr (5.00 kg)" })

    const handleCoffeStockRef = (event) => {
        setCoffeStockRef(event.value);
    };

    const sendDataRef = useRef(null)

    window.onkeydown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            sendDataRef?.current.click()
        }
    }

    // select locations input ------------------------------------------------------------
    const valueChanged = (value) => {
        const dbRefLocation = child(ref(db), `locations/` + `${value.value}`);
        onValue(dbRefLocation, (snapshot) => {

            if (snapshot.exists()) {
                if ("threshold" in snapshot.val()) {
                    setCurrentThreshold(snapshot.val().threshold);
                    console.log(snapshot.val().threshold)
                }
                else
                    setCurrentThreshold(25);
            } else {
            }
        });
        setSelectedLocations(value.value)
    }
    const dbRefLocations = child(ref(db), `locations/`);
    useEffect(() => {
        onValue(dbRefLocations, (snapshot) => {
            if (snapshot.exists()) {
                setLocations(Object.entries(snapshot.val()))
            }
        })
    }, [])
    useEffect(() => {
        //console.warn(locations)
        let options = []
        if (locations && locations.length > 0) {
            locations.forEach((location) => {
                options.push({ "value": location[1].location_id, "label": location[1].location_name })
            })
            setOptions(options)
        }
    }, [locations])
    // select locations input ------------------------------------------------------------


    useEffect(() => {
        //console.warn("options", options)
    }, [options])

    const [tempLoc, setTempLoc] = useState(null)
    const sendData = () => {
        if (selectedLocations) {
            setPadding(true)
            let selectedStocks = {}

            let selected = locations.filter((loc) => loc[0] == selectedLocations)
            const newStocksId = new Date().getTime();
            const oldStocksId = selected[0][0]

            const dbRefStocksOfLocation = child(ref(db), "locations/" + selectedLocations + "/stocks/" + `${newStocksId}/`);
            const dbRefThreshold = child(ref(db), "locations/" + selectedLocations);
            //console.log(selected[0][1])

            set((dbRefStocksOfLocation), {
                coffee: Number(coffeStockRef),
                delight: Number(delightStockRef.current.value),
                technician: TechUid,
                timestamp: newStocksId
            }).then(() => {
                update((dbRefThreshold), {
                    threshold: Number(currentThreshold)
                }).then(() => {
                    console.log("threshold added.")
                }).catch((error) => { alert(error); });
                //console.warn("saved")
                // Data saved successfully!
                setSuccessModal(true)
                setPadding(false)
            }).catch((error) => {
                    //console.error(error)
                    setErrorModal(true)
                    setPadding(false)
                });
        } else {
            setErrorModal(true)
            setPadding(false)
        }

    }

    return (
        <div id='addStocks'>
            <div id="myModal" className={errorModal ? "modal fade show" : "modal fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" /></svg>
                            <button type="button" className="close" onClick={() => setErrorModal(false)} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Ooops!</h4>
                            <p>Something went wrong.</p>
                            <button className="btn btn-success" onClick={sendData} data-dismiss="modal">Try Again</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myModal" className={successModal ? "modal success fade show" : "modal success fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z" /></svg>
                            <button type="button" className="close" onClick={() => window.location.reload()} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Great!</h4>
                            <p>Stock has been successfully added.</p>
                            <button className="btn btn-success" onClick={() => window.location.reload()} data-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="label--input">
                <label>Select Location</label>
                <Select id='job-selector' options={options} onChange={valueChanged} />
            </div>
            <div className='label--input'>
                <label>Coffee Stocks</label>
                <Select id="job-selector" isSearchable={false} options={coffeeStockOptions} onChange={handleCoffeStockRef} />
                {/* <input ref={coffeStockRef} type="number" /> */}
            </div>
            <div className='label--input'>
                <label>Coffee Threshold (cups)</label>
                <input value={currentThreshold} type="number" onChange={(evt) => setCurrentThreshold(evt.target.value)} />
            </div>
            <div className='label--input'>
                <label>Delight Stocks</label>
                <input ref={delightStockRef} type="number" />
            </div>
            <button ref={sendDataRef} className={padding ? 'sendDataButton padding' : 'sendDataButton'} onClick={sendData}>Add stock</button>
        </div>
    )
}

export default AddStockToLoc