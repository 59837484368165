import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';


const animatedComponents = makeAnimated();


let options = [{ value: "all", label: "all" }]

export default function SelectorInputCity({ users, isLoggedIn, cities, setCities, locations, setSelectedCities, selectedCities }) {


  options = [{ value: "all", label: `All` }]
  cities.forEach((city) => {
    options.push({ value: `${city}`, label: `${city}` })
  })

  const [isMulti, setIsMulti] = useState(false)
  useEffect(() => {
  }, [isMulti, selectedCities])

  const onChange = (value) => {

    if ((value.length == 0 || (value.filter((item) => item.value == "all").length == 0))) {
      setIsMulti(true)
      setSelectedCities(value)

    } else {
      setIsMulti(false)
      setSelectedCities(options.slice(1, options.length))
      console.log("all setted")
    }
  }

  useEffect(() => {
    setSelectedCities(options.slice(1, options.length))
  }, [cities])


  return (
    <>
      {
        !isMulti ?
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isClicked ? 'grey' : 'transparent',
              })
            }}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={[]}
            value={[{ value: "all", label: `All` }]}
            defaultValue={options[options.length - 1]}
            onChange={(value) => onChange(value)}
          /> :
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isClicked ? 'grey' : 'transparent',
              })
            }}
            isMulti
            closeMenuOnSelect={false}
            components={animatedComponents}
            options={options}
            onChange={(value) => onChange(value)}
          />
      }
    </>
  );
}