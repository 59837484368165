import React, { useState } from 'react'
import { useEffect } from 'react'
import Select from 'react-select'

let options = [
  { value: 0, label: "Add new location" },
  { value: 1, label: "Add device to location" },
  { value: 2, label: "Add Okka to location" },
  { value: 3, label: "Add cup price with fortune telling to location" },
  { value: 4, label: "Add solo cup price to location" },
  { value: 5, label: "Add Laila service to location" },
  { value: 6, label: "Add stock to location" },
  { value: 7, label: "Add cup to location" },
  { value: 8, label: "Delete cups of location" },
  { value: 9, label: "Add QR reader to location" },
]

const JobSelector = ({ job, setJob }) => {
  const [jobValue, setJobValue] = useState(null)
  useEffect(() => {
    let option = options.filter((option) => option.value == job)
    setJobValue(option)
    console.log("job", job)
  }, [job])
  const valueChanged = (value) => {
    setJob(value.value)
    // console.log(value.value)
  }

  return (
    <Select id='job-selector' selected value={jobValue} options={options} onChange={valueChanged} />
  )
}
export default JobSelector