import { getDatabase, ref, child} from "firebase/database";
import { getFirestore} from 'firebase/firestore'
import { getAuth } from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";


/* FTC IOT Firmware project */
const FTC_IOT_CONFIG = {
    apiKey: "AIzaSyCHtODkCyg8cLYVw6yiU-12UmsUhlcDFTg",
    authDomain: "ftc-iot-software.firebaseapp.com",
    projectId: "ftc-iot-software",
    storageBucket: "ftc-iot-software.appspot.com",
    messagingSenderId: "535419262970",
    appId: "1:535419262970:web:8626e80142eb348f727643"
  };

/* FTC Machines Dev project */
const firebaseConfig = {
  apiKey: "AIzaSyBNb8HzdjUMgipX1Bs7siKwC9NES2aXyNo",
  authDomain: "ftc-machines-dev.firebaseapp.com",
  databaseURL: "https://ftc-machines-dev-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "ftc-machines-dev",
  storageBucket: "ftc-machines-dev.appspot.com",
  messagingSenderId: "918366791343",
  appId: "1:918366791343:web:d27547c9f6cd97f046965a",
  measurementId: "G-25PYRFTPM7"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig, 'base_app');
export const FTC_IOT_CONFIG_APP = initializeApp(FTC_IOT_CONFIG, 'iot_app');

export const auth = getAuth(app);
export const FTC_IOT_AUTH = getAuth(FTC_IOT_CONFIG_APP);


export let locations = []
export const db = getDatabase(app)
export const dbRef = child(ref(db), `locations/`);
export const dbRefDevice = child(ref(db), `devices/`);
export const dbRefAdmins = child(ref(db), `admins/`);
export const dbRefTech = child(ref(db), `technicians/`);
export const dbRefUser = child(ref(db), `users/`);
export const firestoreDB = getFirestore(app)