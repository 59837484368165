import React from 'react'

export default function CardBox({title, svg, boxValue, boxSubValue}) {
    return (
        boxValue !=0   &&
         <div className='label--selector'>
        <div className='data-box'>
            {svg}
            <div>
                <p>{title}</p>
                <>
                    <p>{boxValue}</p>
                    {boxSubValue !== 0 && <p style={{ textAlign: "right", fontSize: "13px", color: "#9a9a9a" }}><b style={{ color: "#000" }}>{boxSubValue}</b></p>}
                </>
            </div>
        </div>
    </div>
    )
}
