import React, { useEffect, useState } from 'react';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import { time } from 'console';
// import { endAt } from 'firebase/database';
// import { isTemplateLiteralTypeSpan } from 'typescript';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  layout: {
    padding: 10
  },
  responsive: true,
  elements: {
    point: {
      radius: 2,
      hoverRadius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Profit Chart',
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: false,
        text: 'Month'
      },
      grid: {
        color: "rgba(255,255,255,0)",
      },
    },
    y: {
      display: true,
      title: {
        display: false,
        text: 'Value'
      },
      grid: {
        color: "rgba(0,0,0, 0)",
      },
    },
  }
};


export const LineChart = ({ setSelectedTimeProfit, startDate, setStartDate, endDate, setEndDate, x, devices, selectedDatasState, selectedDevicesState, selectedShop }) => {
  // const labels = [];
  const [labels, setLabels] = useState([])
  const [timeStampsState, setTimeStampsState] = useState()
  const [lineData, setLineData] = useState()
  const [Button2Data, setButton2Data] = useState()

  let data = {
    labels,
    datasets: [
      {
        label: 'Total Profit',
        data: lineData,
        borderColor: '#a87b52',
        backgroundColor: '#fff',
        color: "#fff",
        pointHoverBackgroundColor: "#a87b52",
      },
    ],
  };
  useEffect(() => {
    if (x[0]) {
    }
  }, [x, devices])

  useEffect(() => {
    if (selectedDatasState && selectedDatasState.length > 0) {
      const selectedTimesDatas = selectedDatasState?.filter((data) => data.length > 0 && data[0][0] <= endDate.getTime() && data[0][0] >= startDate.getTime())

      let timeStamps = []
      selectedDevicesState.forEach((item) => {
        item.forEach((inItem) => {
          let temp = devices.filter((device) => device[0] == inItem)
          timeStamps.push(temp)
        })
      })
      ////console.warn("timeStamps", timeStamps)
      // console.log("timeStamps", timeStamps)
      let deviceIdAndPrice = []
      timeStamps.forEach((timeStamp) => {
        if (timeStamp && timeStamp.length > 0) {
          let deviceId = timeStamp[0][0]
          selectedDatasState.forEach((data) => {
            if (data.length > 0 && data[0] && data[0][1] && data[0][1].devices) {
              Object.entries(data[0][1].devices).forEach((inData) => {
                if (inData[0] == deviceId) {
                  if (timeStamp[0][1].Button1 && !timeStamp[0][1].Button2) {
                    deviceIdAndPrice.push({
                      "id": deviceId,
                      "price": data[0][1].sell_price,
                      "Button1Times": Object.values(timeStamp[0][1].Button1.Timestamp).map((item) => item.Ts),
                      "Button2Times": []
                    })
                  } else if (timeStamp[0][1].Button1 && timeStamp[0][1].Button2) {
                    deviceIdAndPrice.push({
                      "id": deviceId,
                      "price": data[0][1].sell_price,
                      "Button1Times": Object.values(timeStamp[0][1].Button1.Timestamp).map((item) => item.Ts),
                      "Button2Times": Object.values(timeStamp[0][1].Button2.Timestamp).map((item) => item.Ts)
                    })
                  } else if (!timeStamp[0][1].Button1 && timeStamp[0][1].Button2) {
                    deviceIdAndPrice.push({
                      "id": deviceId,
                      "price": data[0][1].sell_price,
                      "Button1Times": [],
                      "Button2Times": Object.values(timeStamp[0][1].Button2.Timestamp).map((item) => item.Ts)
                    })
                  } else if (!timeStamp[0][1].Button1 && !timeStamp[0][1].Button2) {
                    deviceIdAndPrice.push({
                      "id": deviceId,
                      "price": data[0][1].sell_price,
                      "Button1Times": [],
                      "Button2Times": []
                    })
                  }
                }
              })
            }
          })
        }
      })
      setTimeStampsState(deviceIdAndPrice)
    }
  }, [startDate, endDate, selectedDatasState, selectedDevicesState, devices, selectedShop])


  const handleLabel = () => {

    let startDay = startDate.getDate()
    let startMonth = (startDate.getMonth() + 1)
    let startYear = startDate.getFullYear()

    let endDay = endDate.getDate()
    let endMounth = (endDate.getMonth() + 1)
    let endYear = endDate.getFullYear()


    let gap = endDate.getTime() - startDate.getTime()
    let dayGap = Math.round(gap / 86400000)

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    let tempLabels = []
    let tempData = []
    let tempData2 = []
    let uniqeTempData = []
    let finalData = []

    if (timeStampsState != undefined) {
      timeStampsState.forEach((item, itemIndex) => {

        item.Button1Times.forEach((time, timeIndex) => {
          let date = new Date(time)
          if (time >= startDate.getTime() && time <= endDate.getTime()) {
            tempData.push({
              "date": time,
              "price": item.price
            })
          }
        })

        item.Button2Times.forEach((time, timeIndex) => {
          let date = new Date(time)
          if (time >= startDate.getTime() && time <= endDate.getTime()) {
            // //console.log(timeStampsState[itemIndex].Button1Times[timeIndex], date.getFullYear())
            tempData.push({
              "date": time,
              "price": (item.price)
            })
            tempData.push({
              "date": time,
              "price": (item.price)
            })
          }
        })

      })
      let data = tempData
      //console.warn("dayGap:", dayGap)
      //console.warn("tempData:", tempData)


      const times = []
      tempData.forEach((data) => {
        times.push(data.date)
      })
      const sortedTimes = times.sort((a, b) => a - b);


      // ---------------------------------------------------------
      const days = [];
      const profits = [];

      // En düşük ve en yüksek tarih değerleri belirleniyor
      const minDate = startDate.getTime();
      const maxDate = endDate.getTime() - 86400000;
      // Tarih aralığındaki her gün için kar hesaplanıyor
      for (let i = minDate; i <= maxDate; i += 86400000) {
        // Her gün için kar başlangıç değeri 0
        let dailyProfit = 0;

        // Verilerdeki günler ile karşılaştırılıyor
        data.forEach(item => {
          // console.log(item);
          if (item.date >= i && item.date < i + 86400000) {
            dailyProfit += item.price;
          }
        });

        // Günler ve karlar dizilere push ediliyor
        days.push(new Date(i).toLocaleDateString());
        profits.push(dailyProfit);
      }

      // Elde edilen günler ve karlar yazdırılıyor
      //console.log("Günler: ", days);
      //console.log("Karlar: ", profits);




      setLineData(profits)
      setLabels(days)

      let tempCount = 0
      profits.forEach((item) => {
        // console.log(item)
        tempCount += item
      })
      // console.log(tempCount)
      setSelectedTimeProfit(tempCount)
    } else {


    }




  }


  useEffect(() => {
    // handleLabels()
    handleLabel()
  }, [startDate, endDate, selectedShop, timeStampsState, devices])


  useEffect(() => {
    //////console.warn("selectedShop", selectedShop)
    setStartDate(startDate)
  }, [selectedShop])

  return (
    <>
      {/* <div id='datePickerWrapper'>
        <div>
          <label>Start Date</label>
          <div id='bar'></div>
          <DatePicker className="datePicker" id='startDatePicker' selected={startDate} onChange={(date) => setStartDate(date)} />
        </div>
        <div>
          <label>End Date</label>
          <div id='bar'></div>
        <DatePicker className="datePicker" id='endDatePicker' selected={endDate} onChange={(date) => setEndDate(date)} />
        </div>
      </div> */}
      <Line options={options} data={data} />
    </>
  );
}
