import React, { useEffect, useRef, useState } from 'react';
// import { MapContainer, Popup, Marker, TileLayer, useMapEvents } from 'react-leaflet';
// import makeAnimated from 'react-select/animated';
import { child, push, ref, set, onValue, get } from "firebase/database";
import { auth, db, FTC_IOT_AUTH } from "../../firebase/FireBase"
import { createUserWithEmailAndPassword } from 'firebase/auth';
import Select from 'react-select';
// import { getAllByAltText } from '@testing-library/react';


let options = []

const AddOkka = () => {

    const valueChanged = (value) => {
        setSelectedLocations(value.value)

    }
    const [padding, setPadding] = useState(false)
    const [TechUid, setTechUid] = useState(sessionStorage.getItem("TechUid"))
    const [errorModal, setErrorModal] = useState(false);
    const [serialErrorModal, setSerialErrorModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [options, setOptions] = useState([])
    const [selectedLocations, setSelectedLocations] = useState(null)
    const [locations, setLocations] = useState(null)
    const serialNumberRef = useRef()
    const macNumberRef = useRef()
    const sendDataRef = useRef(null)



    window.onkeydown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            sendDataRef?.current.click()
        }
    }

    const locationId = new Date().getTime();

    const dbRefLocations = child(ref(db), `locations/`);


    useEffect(() => {
        onValue(dbRefLocations, (snapshot) => {
            if (snapshot.exists()) {
                setLocations(Object.entries(snapshot.val()))
            }
        })
    }, [])

    useEffect(() => {
        //console.warn(locations)
        let options = []
        locations?.forEach((location) => {
            options.push({ "value": location[1].location_id, "label": location[1].location_name })
        })
        setOptions(options)
    }, [locations])

    const sendData = () => {

        if (selectedLocations) {
            setPadding(true)

          const serialNumber = serialNumberRef.current.value
          if (serialNumber.length >= 9) {
            const date = new Date();
            setSuccessModal(true)

            if (1 === 1) {

                const dbRefLocOkka = child(ref(db), `locations/` + selectedLocations + "/okka/" + `${serialNumber}`);

                set((dbRefLocOkka), {
                  technician: TechUid,
                  timestamp: date.getTime(),
                }).then(() => {
                    setPadding(false)
                    const dbRefDevices = child(ref(db), `okka/` + `${serialNumber}` + `/${selectedLocations}`);
                    set((dbRefDevices), {
                        technician: TechUid,
                        timestamp: date.getTime()
                    }).then(() => {
                        console.log("saved")
                    })
                    .catch((error) => {
                        console.log(error)
                        console.log("saved")
                        setErrorModal(true)

                    });
                })
                .catch((error) => {
                    //console.error(error)
                });
            }
          }
          else {
              setSerialErrorModal(true)
              setPadding(false)
              return
          }
        } else {
            setErrorModal(true)
        }
    }


    // const handleMacInput = () => {
    //     let macNumber = macNumberRef.current.value

    //     macNumberRef.current.value = macNumberRef.current.value.toUpperCase()
    //     //console.warn(macNumber)
    //     let macNumberArr = Array.from(macNumber)
    //     let length = macNumberArr?.filter(letter => letter != ":").length

    //     if (length == 12) {
    //     } else {
    //         macNumberRef.current.onkeydown = (event) => {
    //             if (event.key == "Backspace") {
    //                 //console.error(macNumberArr[macNumberArr.length - 1])
    //                 if (macNumberArr[macNumberArr.length - 2] == ":") {
    //                     const newValue = macNumber.slice(0, -1);
    //                     macNumberRef.current.value = newValue
    //                 }
    //                 else {

    //                 }
    //             } else {
    //                 if (length % 2 == 0 && length != 0) {
    //                     macNumberRef.current.value = macNumber + ":"
    //                 } else {
    //                     macNumberRef.current.value = macNumber
    //                 }
    //             }
    //         }
    //     }
    // }

    return (
        <div id='addDevices'>
            <div id="myModal" className={serialErrorModal ? "modal fade show" : "modal fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" /></svg>
                            <button type="button" className="close" onClick={() => setErrorModal(false)} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Ooops!</h4>
                            <p>Please add device's serial number.</p>
                            <button className="btn btn-success" onClick={sendData} data-dismiss="modal">Try Again</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myModal" className={errorModal ? "modal fade show" : "modal fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" /></svg>
                            <button type="button" className="close" onClick={() => setErrorModal(false)} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Ooops!</h4>
                            <p>Something went wrong.</p>
                            <button className="btn btn-success" onClick={sendData} data-dismiss="modal">Try Again</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myModal" className={successModal ? "modal success fade show" : "modal success fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z" /></svg>
                            <button type="button" className="close" onClick={() => window.location.reload()} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Great!</h4>
                            <p>Okka has been successfully added.</p>
                            <button className="btn btn-success" onClick={() => window.location.reload()} data-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="label--input">
                <label>Select Location</label>
                <Select id='job-selector' options={options} onChange={valueChanged} />
            </div>
            <div className='label--input'>
                <label>Serial Number</label>
                <input ref={serialNumberRef} type='text' />
            </div>
            {/* <div className='label--input'>
                <label>Mac Number</label>
                <input
                    ref={macNumberRef}
                    maxLength={17}
                    onChange={handleMacInput}
                    placeholder='Device Mac Number ( ex. 12:DC:23:EF:AA:00)'
                />
            </div> */}
            <button ref={sendDataRef} className={padding ? 'sendDataButton padding' : 'sendDataButton'} onClick={sendData}>Add Okka</button>
        </div>
    )
}

export default AddOkka;