import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';


const animatedComponents = makeAnimated();

let options = []

export default function SelectorInputLocal({ locals, setSelectedLocals }) {

  options = [{ value: "all", label: `All` }]
  const [isMulti, setIsMulti] = useState(false)

  locals.forEach((local) => {
    // console.error(local)
    options.push({ value: `${local}`, label: `${local}` })
  })


  const onChange = (value) => {

    if ((value.length == 0 || (value.filter((item) => item.value == "all").length == 0))) {
      setIsMulti(true)
      setSelectedLocals(value)

    } else {
      setIsMulti(false)
      setSelectedLocals(options.slice(1, options.length))
    }
  }

  useEffect(() => {
    setSelectedLocals(options.slice(1, options.length))
  }, [locals])

  return (

    <>
      {
        locals.length == 0 ?
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isClicked ? 'grey' : 'transparent',
              })
            }}
            isDisabled
          /> :
          <>
            {
              !isMulti ?
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isClicked ? 'grey' : 'transparent',
                    })
                  }}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={[]}
                  value={[{ value: "all", label: `All` }]}
                  defaultValue={options[options.length - 1]}
                  onChange={(value) => onChange(value)}
                /> :
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isClicked ? 'grey' : 'transparent',
                    })
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={options}
                  onChange={(value) => onChange(value)}
                />
            }
          </>
      }
    </>

  );
}