import React, { useState, useEffect, useMemo, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import coffeeIcon from '../images/coffee_marker.svg'

function MapWithCurrentLocation({ setuserPositions }) {
  const [position, setPosition] = useState(null);
  const [address, setAddress] = useState(null);

  const iconPerson = new L.Icon({
    iconUrl: coffeeIcon,
    iconRetinaUrl: coffeeIcon,
    iconAnchor: [23, 40],
    popupAnchor: [0, -40],
  });

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (pos) => {
        const { latitude, longitude } = pos.coords;
        setPosition([latitude, longitude]);
        getAddress(latitude, longitude);
      },
      (error) => console.log("leaflet", error)
    );
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const drag = () => {
    //console.log("drap")
  }

  const markerRef = useRef()

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
          //console.log("what", marker.getLatLng())
          getAddress(marker.getLatLng().lat, marker.getLatLng().lng);
        }
      },
    }),
    [],
  )


  const getAddress = async (lat, lng) => {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`);
    const data = await response.json();
    setAddress(data.display_name);

    setuserPositions({
      "country": data.address.country,
      "city": (typeof data.address.city != undefined ? data.address.city : data.address.city_district),
      "cords": { "x": lat, "y": lng }
    })
  }


  useEffect(() => {
    //console.log(position, address)
  }, [position, address])


  return (
    <>
      {
        position != null ?
          <MapContainer center={position} zoom={20}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {position && <Marker icon={iconPerson} eventHandlers={eventHandlers} ref={markerRef} draggable={true} position={position}><Popup>Your current location: {address}</Popup></Marker>}
          </MapContainer> :
          null
      }
    </>
  );
}

export default MapWithCurrentLocation;