// import { onValue, set } from 'firebase/database'
import React, { useEffect } from 'react'
import Select from 'react-select'
// import { dbRefDevice } from "./FireBase.js"

const maxTime = 2147483647000;
const options = [
    { value: 0, label: 'To date' },
    { value: 1, label: 'Yesterday' },
    { value: 2, label: 'Last 7 days' },
    { value: 3, label: 'Last 30 days' },
    { value: 4, label: 'Custom date' }
]


const DataRange = ({ minTime, dataRange, setDataRange, setIsCustomRange, selectedOption, setSelectedOption }) => {

    useEffect(() => {
        //console.warn("minTime", minTime)
        setDataRange({
            "start": new Date(minTime),
            "end": new Date(new Date().getTime() + 2 * 86400000),
            "option": (typeof selectedOption == "undefined" ? 0 : selectedOption.value)
        })
        setIsCustomRange(false)
    }, [minTime])





    const onChange = (selectedOption) => {
        if (selectedOption.value == 0) {
            setDataRange({ "start": new Date(minTime), "end": new Date(new Date().getTime() + 2 * 86400000), option: selectedOption.value }) // must be
            setIsCustomRange(false)
        }
        else if (selectedOption.value == 1) {
            setDataRange({ "start": new Date(new Date().getTime() - 86400000), "end": new Date(new Date().getTime() + 86400000), option: selectedOption.value })
            setIsCustomRange(false)
        }
        else if (selectedOption.value == 2) {
            setDataRange({ "start": new Date(new Date().getTime() - 518400000), "end": new Date(), option: selectedOption.value })
            setIsCustomRange(false)
        }
        else if (selectedOption.value == 3) {
            setDataRange({ "start": new Date(new Date().getTime() - 2592000000), "end": new Date(), option: selectedOption.value })
            setIsCustomRange(false)
        }
        else if (selectedOption.value == 4) {
            setIsCustomRange(true)
        }
    }
    return (
        <Select
            defaultValue={options[0]}
            options={options}
            onChange={onChange}
            // isDisabled={true}
        />
    )
}

export default DataRange;