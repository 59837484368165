import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';


const animatedComponents = makeAnimated();

let options = []
export default function SelectorInputShop({ shops, setSelectedShop, selectedShop }) {
  options = [{ value: "all", label: `All` }]

  const [isMulti, setIsMulti] = useState(false)

  shops.forEach((shop) => {
    options.push({ value: `${shop.id}`, label: `${shop.name}` })
  })

  const onChange = (value) => {

    if ((value.length == 0 || (value.filter((item) => item.value == "all").length == 0))) {
      setIsMulti(true)
      setSelectedShop(value)

    } else {
      setIsMulti(false)
      setSelectedShop(options.slice(1, options.length))
    }
  }

  const [allOptions, setAllOptions] = useState()

  // //what is THAT?
  useEffect(() => {
    if (shops.length > 0 && selectedShop.length == 0) {
      // console.log('selectedShop', selectedShop)

      setSelectedShop(options.slice(1, options.length))

    }

  }, [shops])

  return (
    <>
      {
        shops.length == 0 ?
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isClicked ? 'grey' : 'transparent',
              })
            }}
            isDisabled
          /> :
          <>
            {
              !isMulti ?
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isClicked ? 'grey' : 'transparent',
                    })
                  }}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={[]}
                  value={[{ value: "all", label: `All` }]}
                  defaultValue={options[options.length - 1]}
                  onChange={(value) => onChange(value)}
                /> :
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isClicked ? 'grey' : 'transparent',
                    })
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={options}
                  onChange={(value) => onChange(value)}
                />
            }
          </>
      }
    </>
  );
}