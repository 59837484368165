import React, { useEffect, useState } from "react";
import Admin from "./pages/admin/Admin"
import Technicians from "./pages/technician/Technicians";
// import User from "./User"
import Login from "./pages/auth/Login"


function App() {
  console.error = () => { };
  // console.log = () => { };
  console.warn = () => { };

  const [isLoggedIn, setIsLoggedIn] = useState("false")
  const [userCred, setUserCred] = useState(null)
  const [tech, setTech] = useState()
  const [admins, setAdmins] = useState()
  const [users, setUsers] = useState()

  // ==================================================================================================================================
  // For real time auth check =========================================================================================================
  // ==================================================================================================================================

  useEffect(() => {
    if (sessionStorage.getItem("isLoggin") && sessionStorage.getItem("isLoggin") !== "false") {
      setIsLoggedIn(sessionStorage.getItem("isLoggin"))
    }
    console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)
  }, [])

  // ==================================================================================================================================
  // ==================================================================================================================================
  // ==================================================================================================================================

  return (
    <>
      {
      isLoggedIn === "admin" ?
        <Admin userCred={userCred} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> :
        isLoggedIn === "owner" ?
          <Admin userCred={userCred} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> :
          isLoggedIn === "tech" ?
            <Technicians isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> :
            isLoggedIn === "user" ?
              <Admin userCred={userCred} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> :
              isLoggedIn === "false" &&
                <Login users={users} setUsers={setUsers} admins={admins} setAdmins={setAdmins} tech={tech} setTech={setTech} setUserCred={setUserCred} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                  
      }
    </>
  )
}
export default App